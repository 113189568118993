.description-card-container {
  width: 20vw;
  height: 45vh;
  /* background-color: rgb(205, 253, 60); */
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
 margin-right: 3vw;
}


.description-card-title {
  font-size: 3vw;
  font-weight: bold;
  text-transform: uppercase;

}




.description-arrow{
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 40px;
  transform: rotate(45deg)
}


.light-green {
  background-color: rgb(205, 253, 60);
}

.black{
  background-color: black;
}


.white {
  background-color: rgb(255, 255, 255, 0.2);
  /* border: 2px solid black; */
  box-shadow: 2px 2px 2px 2px black;

}



.modern .description-card-container {
  width: 25vw;
  height: 60vh;
  /* background-color: rgb(205, 253, 60); */
  border-radius: 3px;
  background-color: rgb(17, 17, 17);
  background-color: white;
  background: linear-gradient(0deg,#000000 5%,rgb(255, 255, 255) 50% );
  /* background: linear-gradient(180deg,rgb(36, 36, 36) 1%, #ffffff 50%,  rgb(0, 0, 0)95%); */
  /* color: white; */
  color: black;
  /* border: 6px solid white; */
  font-family:'Bai Jamjuree' ;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
 margin-right: 3vw;
 text-align: center;

    /* box-shadow: inset 5px 5px 10px rgba(0, 0, 0, 0.05), inset -2px -2px 20px rgb(85, 85, 85); */
}


.poppy .description-card-container {
  width: 25vw;
  height: 60vh;
  /* background-color: rgb(205, 253, 60); */
  border-radius: 3px;
  /* background: linear-gradient(230deg,#ea426f 5%,rgb(255, 255, 84) 50% ); */
  background: linear-gradient(180deg,#ed5e84dd 5%,rgb(255, 255, 84) 90% );
  /* background: linear-gradient(150deg,rgb(255, 255, 84) 10%, #ed5e84 5%) ; */
  /* background: linear-gradient(180deg,rgb(255, 255, 84) 10%, #ed5e84 50%,  rgb(255, 255, 84)95%); */
  color: black;
  -webkit-text-stroke: 2px black;
  -webkit-text-fill-color: rgb(255, 255, 84);
  /* Optional: set the text color */
  text-shadow: 5px 7px 20px #d2213f;

  -webkit-text-stroke: 1.5px black;
  -webkit-text-fill-color: rgb(248, 248, 248);
  /* Optional: set the text color */
  text-shadow: 5px 7px 20px #d2213f;
  /* letter-spacing: -3px; */
  font-family:'Prompt', sans-serif ;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
 margin-right: 3vw;
 text-align: center;
}




/* Mobile styles for landing page */
@media (max-width: 767px) {
  .description-card-container.landing-page-card {
    width: 50vw;
    height: auto;
    min-height: 200px;
    margin-right: 0;
    margin-bottom: 20px;
    padding: 20px;
  }

  .description-card-container.landing-page-card .description-card-title {
    font-size: 6vw;
    text-align: center;
  }

  .description-card-container.landing-page-card .description-arrow {
    top: 10px;
    right: 10px;
    font-size: 24px;
  }
}
