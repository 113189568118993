.footer-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
  height: 15vh;
  width: 100%;
  border-top: black 1px solid;
  background-color: white;
  justify-content: space-between;
}

.footer-left {
  display: flex;
  flex-direction: column;
  justify-content: left;
  padding-left: 50px;

}


.footer-right {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-right: 50px;
}


.footer-links {
  padding: 15px 15px;

  text-decoration: none;
  color: black;

}

.footer-links:hover{
  color: blue;
}


/* Mobile styles for landing page */
@media (max-width: 767px) {
  .footer-container {
    flex-direction: column;
    height: auto;
    padding: 20px 0;
  }

  .footer-left {
    padding: 0 20px;
    margin-bottom: 20px;
    text-align: center;
  }

  .footer-right {
    padding: 0;
  }

  .footer-links {
    padding: 10px;
  }
}
