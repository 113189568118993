/* .login-full-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  margin: 0 auto;
  background: linear-gradient(90deg, #9ef9dc, #97dbf2);

}

.login-container {
  height: 70%;
  min-width: 450px;
  border-radius: 12px;
  background-color: rgb(255, 255, 255);
  overflow: auto;
} */

/* .form-container {
  display: flex;
  padding: 0px 25px;
  flex-direction: column;
  justify-content: center;
  margin: 10px 0px;
} */

/* .login-input {
  display: flex;
  flex-direction: column;
  width: 70%;
  height: 25px;
  border-radius: 5px;
  margin: 10px 0px;
} */

.brand-login-container {
  /* height: 550px; */
  width: 400px;
  /* border: 1px solid black; */
  border-radius: 12px;
  box-shadow: 15px 15px 20px rgba(0, 0, 0, 0.3),
    -15px -15px 50px rgba(84, 84, 84, 0.245);

  /* padding:80px 50px 50px; */
  background-color: rgba(236, 236, 236, 1);
}



.top-header-store {
  display: flex;
  margin: 50px 25px;
  margin-top: 50px;
  margin-bottom: 10px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

}

.create-store-button {
  padding: 5px 10px;
  font-size: 14px;
  margin: 0;
  height: 35px;
  align-items: center;
  justify-content: center;
  background-color: rgb(0, 128, 96);
  color: white;
  border: none;
  border-radius: 3px;

  transition: border 0.3s, box-shadow 0.3s, background-color 0.3s;
}
.create-store-button:hover{
  background-color: rgb(1, 105, 79);
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.3);
}

.storesbrand-login{
  font-weight: normal;
  color: rgb(46, 46, 46);
}


.brand-login-brand-container{
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rgb(224, 224, 224);
  padding: 30px;
  margin-bottom: 15px;
  border-radius: 3px;
  overflow: auto;
  transition: border 0.3s, box-shadow 0.3s;
}
.brand-login-brand-container:hover{
  cursor: pointer;
  border: 3px solid rgb(8, 91, 255);
  box-shadow: 4px 4px 15px rgba(0, 0, 0, 0.5);

}


.brand-login-brand-outer-container{
  overflow: auto;
  height:40vh;
  padding-right: 10px;
}


.brand-login-form-container{
  overflow-y: hidden;
}



.brand-list-form-container{
  display: flex;
  padding: 0px 25px;
  flex-direction: column;
  justify-content: center;
  /* margin: 0px 0px; */
  margin-bottom: 60px;
  /* overflow: hidden; */

}


.brand-login-brand-outer-container::-webkit-scrollbar {
  width: 6px;
  background-color: transparent;
}

.brand-login-brand-outer-container::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 3px;
}

.brand-login-brand-outer-container::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.3);
}

.brand-login-brand-outer-container::-webkit-scrollbar-track {
  background-color: transparent;
}
