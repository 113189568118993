.create-brand-full-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 120vh;
  /* width: 300px; */
  /* border: 1px black solid; */
  margin: 0 auto;
  background: linear-gradient(90deg, #9ef9dc, #97dbf2);

}

.create-brand-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 650px;
  width: 750px;
  /* padding-bottom: 50px; */
  /* border: 1px solid black; */
  padding: 20px;
  border-radius: 12px;
  position: relative;
  background-color: rgba(236, 236, 236, 1);
}

.form-container {
  display: flex;
  padding: 0px 25px;
  flex-direction: column;
  justify-content: center;
  margin: 10px 0px;
  height: 100%;
}

/* .login-input {
  display: flex;
  flex-direction: column;
  width: 70%;
  height: 25px;
  border-radius: 5px;
  margin: 10px 0px;
} */


.create-brand-top-header-store {
  display: flex;
  /* margin: 50px 0px; */
  /* margin-bottom: 20px; */
  margin-top: 100px;
  /* padding-top: 50px; */
  padding: 20px 0;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-size: 2.4rem;
  line-height: 1;
  font-weight: 600;
  width: 100%;
  /* line-height: 2.8rem; */
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", Segoe UI Symbol, "Noto Color Emoji";
}

.create-brand-top-header-store-bottom {
  display: flex;
  /* margin: 50px 0px; */
  margin-top: 0px;
  margin-bottom: 30px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-size: 1rem;
  line-height: 1;
  font-weight: 600;
  /* line-height: 2.8rem; */
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", Segoe UI Symbol, "Noto Color Emoji";
}

.create-store-button {
  padding: 5px 10px;
  font-size: 14px;
  margin: 0;
  height: 30px;
  align-items: center;
  justify-content: center;
  background-color: rgb(0, 128, 96);
  color: white;
  border: none;
  border-radius: 3px;
}

.storesbrand-login {
  font-weight: normal;
  font-size: 40px;
  font-family: ShopifySans,Helvetica,Arial,sans-serif;
  color: rgb(2, 2, 2);
}


.description {
  display: flex;
  flex-direction: row;
  resize: none;
  /* height: 30px; */
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", Segoe UI Symbol, "Noto Color Emoji";
  width: 500px;
}

.brand-story {
  resize: none;
  height: 150px;
  width: 500px;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", Segoe UI Symbol, "Noto Color Emoji";
}


.create-brand-small-text {
  font-size: 12px;
  color: grey;
}

.create-brand-create-button {
  margin-bottom: 100px;
}




.edit-brand-delete-button {
  position: absolute;
  top: 25px;
  right: 25px;
  border: none;
  padding: 15px 15px;
  background-color: rgb(219, 4, 4);
  border-radius: 15px;
  color: white;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.9),
    -5px -5px 10px #fff;
  cursor: pointer;
  background: rgb(186, 21, 21);
}

.edit-brand-delete-button:hover{
transform: translateY(5px);
box-shadow: none;

}


.create-brand-error{
  font-size: 13px;
  color:red
}
