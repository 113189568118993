.profile-dropdown {
  position: absolute;
  background-color: rgb(250, 250, 250);
  top: 75px;
  right: 10px;
  width: 200px;
  height: 350px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: baseline;
  text-decoration: none;
  list-style-type: none;
  z-index: 3;
  box-shadow: 2px 2px 10px 0px black;
}

.profile-dropdown li {
  list-style-type: none;
  padding: 0;
  margin: 0;


}

.profile-dropdown-logo {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.profile-dropdown-icon {
  color: grey;
  margin-right: 20px;
  width: 10px;
  text-align: center;
}

.profile-dropdown-arrow {
  background-color: grey;
  color: white;
  border: 1px solid black;
  border-radius: 50%;
  width: auto;
  height: auto;
}

.profile-contents {
  margin: 10px 0px;
  margin-left: 20px;
  cursor: pointer;
}

.hidden {
  display: none;
}

.nav-container {
  display: flex;
  align-items: center;
  text-decoration: none;
  list-style-type: none;
  justify-content: space-between;
  padding: 2px 0px;
  /* padding-top: 11px; */
  margin: 0px;
  height: 60px;
  z-index: 1;
  /* opacity: 0;
  transition: opacity 0.3s ease-in; */
  /* border-bottom: 1px solid rgb(232, 232, 232); */
  border-bottom: 1.5px solid#f1f2f4;
  /* background-image: linear-gradient(to bottom,
      rgb(33 44 55 / 0),
      rgb(33 44 55 / 0),
      rgb(33 44 55 / 0.75)); */

}

.nav-bar-icon-dropdown {
  margin-left: 30px;
  cursor: pointer;
}

.nav-container.visible {
  opacity: 1;
}

.nav-logo-explore {
  font-size: 15px;
  font-weight: bold;
  text-align: center;
  padding: 10px 0px;
  margin-right: 0px;
  /* background-color: black; */
  border-radius: 50%;
  /* text-transform: uppercase; */
  font-family: 'syncopate-bold';
  text-transform: uppercase;

}

.nav-logo-explore:hover {
  color: rgb(0, 0, 184);
  cursor: pointer;
}


.nav-link {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: black;
}

.nav-item {
  margin-right: 10px;
}

.nav-item-right {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: auto;
  margin-right: 50px
}

.nav-item-left {
  display: flex;
  margin-right: auto;

  margin: 0px;
  margin-left: 40px;
}

.nav-logo {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 0px;

}

.nav-logo-text {
  font-weight: 600;
  font-size: 2vw;
  font-style: italic;


}

.logo-image {
  width: 2.3vw;
  height: auto;
  padding-right: 5px;
}


.nav-login-signup-container {
  display: flex;
  flex-direction: row;
  margin: 5px 50px;
}

.nav-login {
  margin-left: 30px;
  cursor: pointer;
  /* border: 1px solid black; */
  padding: 15px 15px;
  border-radius: 20px;
  text-decoration: none;
  color: black;
}

.nav-signup {
  margin-left: 20px;
  cursor: pointer;
  color: white;
  /* border: 1px solid black; */
  padding: 15px 35px;
  border-radius: 25px;
  background-color: black;
  text-decoration: none;
}

.nav-signup:hover {
  background-color: rgb(69, 69, 69);
}
