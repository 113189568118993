/* .add-product-left-right-container {

  width: 70vw;
  justify-content: center;
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  overflow: hidden;
  overflow-y: scroll;


}

.add-product-left-container-main {
  margin-left: 20px;
  width: auto;
}

.add-product-left-container-individual {
  background-color: white;
  display: flex;
  flex-direction: column;

  margin-right: 10px;
  margin-bottom: 20px;
  border-radius: 7px;
  box-shadow: 0px 2px 5px 0px rgb(169, 169, 169);
}

.add-product-right-container-individual {
  background-color: white;
  display: flex;
  flex-direction: column;
  margin-right: 10px;
  margin-bottom: 20px;
  border-radius: 7px;
  box-shadow: 0px 2px 5px 0px rgb(169, 169, 169);
}

.title-description {
  height: 250px;
}

.status{
  height:100px;
}

.media {
  height: 150px;
  padding-top: 50px;
}

.add-product-right-container {
margin-left: 15px;
  width: auto;

}


.container {
  display: flex;
  flex-wrap: wrap;

}

.column {
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: white;
}

.row {
  flex: 1;
  display: flex;
  flex-direction: row;
}

.box {
  flex: 1;
  box-shadow: 0px 2px 2px 0px rgb(169, 169, 169);
  height: 200px;
}

.feed-test-container {
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
  width: 100vw;
  box-sizing: border-box;
}


.feed-test-photo {
  flex: 0 0 .33%;
  max-width: 90.33%;

  padding: 10px;
  position: relative;
}

.feed-test-photo img {
  width: 100%;
  height: auto;
  border-radius: 15px;
  filter: brightness(100%);

} */


.product-list-delete-product {
  background-color: #c80404;
  position: absolute;
  color: white;
  border-radius: 5px;
  border: none;
  padding: 10px 15px;
  bottom: 3vw;
  right: 15vw;
}
.product-list-delete-product:hover{
  background-color: rgb(121, 0, 0);
}


.add-product-page-description {
  width: 90%;
  text-align: left;
  overflow: auto;
  resize: none;
  height: 120px;
  /* margin-left: 1.7vw; */
  font-size: 0.875rem;
  line-height: 1.4;
  border-radius: 5px;
  margin-top: 10px;
  padding: 10px 10px;
  font-family: -apple-system, BlinkMacSystemFont, San Francisco, Segoe UI, Roboto, Helvetica Neue, sans-serif;

}


.add-product-page-title-input {
  width: 90%;
  text-align: left;
  border: 1px solid black;
  overflow: auto;
  font-size: 0.875 rem;
  line-height: 1.4;
  height: 10px;
  ;
  border-radius: 5px;
  margin-top: 10px;
  padding: 10px 10px;
  font-family: -apple-system, BlinkMacSystemFont, San Francisco, Segoe UI, Roboto, Helvetica Neue, sans-serif;

}

.add-product-page-description-text {
  margin-top: 10px;
  margin-bottom: 0px;
  margin-left: 1.7vw;
}

.add-product-page-image {
  display: flex;
  flex-direction: column;
  width: 90%;
  margin: 20px 0px;
  height: 30px;

}



.add-product-left-container-images {
  /* height: 100%; */
  /* width: 100%; */
  /* display: flex; */
  flex-direction: column;
  align-items: center;
  margin: 30px 10px ;

}


.media {
  /* height: 150px; */
  /* padding-top: 50px; */
  padding: 20px;
}

.add-product-page-feature {
  margin: 20px 0px;
  width: 90%;
  height: 100%;
  border-radius: 5px;
  border: 1px solid black;
  height: 30px;

}

.add-product-page-feature-container-text {
 margin: 0px 0px ;
}

.add-product-page-feature-container {
  margin: 50px 30px ;
 }





 /* .add-product-image-preview img{
    width: 50%;

 } */


 /* .add-product-image-media-text{
  margin-bottom: 20px;
} */
