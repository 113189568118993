.dashboard-container {
  display: flex;
  flex-direction: row;
  background-color: #f1f2f4;
  height: calc(100vh - 67px);
  width: 100vw;

}

.side-menu {
  display: flex;
  flex-direction: column;
  padding-right: 90px;
  border: 1.5px solid rgb(224, 224, 224);
  height: calc(100vh - 67px);
  border-bottom: none;
  border-top: none;
  position: relative;

}

.inidividual-choice {
  display: flex;
  flex-direction: row;
  color: rgb(57, 57, 57);
  align-items: center;
  margin: 10px 30px;
  font-family: -apple-system, BlinkMacSystemFont, "San Francisco", "Segoe UI", Roboto, "Helvetica Neue", sans-serif;
  font-size: 1rem;
  font-weight: 500;
  width:100%;
  padding: 10px 0px;
}

.side-bar-text{
  margin-left: 10px
}

.inidividual-choice:hover {
  color:rgb(1, 91, 1);
  cursor: pointer;
  background-color: rgb(218, 218, 218);
  border-radius: 2px;
}

.settings {
  position: absolute;
  bottom: 5px;
  max-width: 70%;
}

/* .main-container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
} */

/* .grid-container {
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
cursor: pointer;

} */

/* .grid-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 200px;
} */

/* .grid-cell {
  flex: 1;
  height: 200px;
  width: 200px;
  background-color: #ccc;
  border: 1px solid #000;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  width: 100%;
} */
