.modern .brand-scroll-container {
  box-sizing: border-box;
  background-color: rgb(197, 197, 197);
  display: flex;
  flex-direction: row;
  /* width: 100vw; */
  margin: 0 0;
  padding: 0 0;
  border-top: 5px solid black;
  border-bottom: 5px solid black;

}

.poppy .brand-scroll-container {
  box-sizing: border-box;
  background: linear-gradient(to right, rgb(247, 65, 65), rgb(253, 180, 45));
  display: flex;
  flex-direction: row;
  /* width: 100vw; */
  margin: 0 0;
  padding: 0 0;
  border-top: 5px solid black;
  border-bottom: 5px solid black;

}

.logos {
  overflow: hidden;
  padding-top: 10px;
  padding-bottom: 10px;
  white-space: nowrap;
display: flex;
flex-direction: row;
}

.logos-slide div {
  font-size: 25px;
  margin: 0 40px;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;

}

.logos-slide {
  display: inline-block;
  display: flex;
  align-items: center;
  /* flex-direction: row;
  align-items: center; */

  height: 50px;


  animation: 20s slide linear infinite;
}


@keyframes slide {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}
