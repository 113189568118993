.product-list-dashboard-container {
  display: flex;
  flex-direction: row;
  background-color: #f1f2f4;
  height: calc(100vh - 67px);
  width: 100%;
}

.product-list-side-menu {
  display: flex;
  flex-direction: column;
  padding-right: 90px;
  border: 1.5px solid rgb(224, 224, 224);
  height: calc(100vh - 67px);
  border-bottom: none;
  border-top: none;
  position: relative;
}

.product-list-inidividual-choice {
  display: flex;
  flex-direction: row;
  color: rgb(57, 57, 57);
  align-items: center;
  margin: 10px 30px;
  font-family: -apple-system, BlinkMacSystemFont, "San Francisco", "Segoe UI", Roboto, "Helvetica Neue", sans-serif;
  font-size: 1rem;
  font-weight: 500;
  width: 100%;
  padding: 10px 0px;
}

.product-list-side-bar-text {
  margin-left: 10px;
}

.product-list-inidividual-choice:hover {
  color: rgb(1, 91, 1);
  cursor: pointer;
  background-color: rgb(218, 218, 218);
  border-radius: 2px;
}

.product-list-settings {
  position: absolute;
  bottom: 5px;
  max-width: 70%;
}


.product-list-main-container {
  display: flex;
  flex-direction: column;
  width: 80%;
  margin: 0px 30px;


}



.product-list-top-bar{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 25px 10px;
  /* background-color: #fff; */
  /* border-bottom: 1px solid #e0e0e0; */
}

.product-list-products-text{
  font-weight: 600;
  font-size: 17px;
}


.product-list-add-product{
  background-color: #007a5c;
  color:white;
  border-radius: 5px;
  border:none;
  padding: 10px 15px;
}


.product-list-white-container{
  display: grid;
  /* grid-template-columns: repeat(6, 1fr)
   width: 100%;
  /* height:300px; */
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 2px 4px 2px rgba(0, 0, 0, 0.1);

}

.product-list-rows-container{
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  gap: 15px;
  padding: 10px 15px;
  font-size: 13px;
  align-items: center;

}


.store-icon{
 font-size: 22px;
margin-left:10px;
color: rgb(3, 85, 25);
}


.product-list-grid-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.product-list-grid-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 200px;
}

.product-list-grid-cell {
  flex: 1;
  height: 200px;
  width: 200px;
  background-color: #ccc;
  border: 1px solid #000;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  width: 100%;
}


.background-grey{
  background-color: rgb(245, 249, 253);
  border-top: .5px solid grey;
  border-bottom: .5px solid grey;
  border-radius: 5px;
  color: rgb(120, 120, 120);
  font-size: 12px;
}
.product-list-small-image {
  height: 40px;
  border-radius: 10px;
  width: 40px;
object-fit: cover;
}
.product-list-small-image:hover{
  box-shadow: 2px 2px 6px 0px rgb(86, 86, 86);
}

.product-list-small-image img {
  height: 100%;
  width: 100%;

}

.product-list-grey-hover:hover{
  background-color: rgb(238, 238, 238);
  cursor: pointer;
}

.product-list-grey-hover{
  padding: 10px 15px;
  align-items: center;
}

.product-list-rows-active{
  background-color: #a1edd0;
  border-radius: 20px;
  display: inline-block;
  /* width: 50px;
  padding-left: 15px; */
  white-space: nowrap;
  text-align: left;
}
