.modern .shopping-cart-main-container {
  display: flex;
  flex-direction: column;
  background-color: rgb(255, 255, 255);
  /* height: calc(100vh - 67px); */
  height: 100vh;
  width: 40vw;
  align-items: center;
  position: fixed;
  top: 0;
  right: -100vw;
  z-index: 300;
  color: rgb(0, 0, 0);
  z-index: 3;
  transition: right 1s ease;
  /* display: none; */

}


.modern .shopping-cart-bottom-check-out {

  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  padding: 15px;
  /* border: 2px solid black; */
  border-radius: 7px;
  /* width: 100%; */
  /* width: 100%; */

  background-color: #000000;

  /* background-color: #D3335D; */
  color: rgb(241, 241, 241);
  font-size: 19px;
  font-weight: 600;
  z-index: 1;
  cursor: pointer;
  /* box-shadow: 0px 6px 0px 0px black; */
  transition: transform 0.1s ease-in-out, box-shadow 0.1s ease-in-out;

}
