/* TODO Add site wide styles */

body{
  font-family: ShopifySans,Helvetica,Arial,sans-serif;
}


html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
}


.material-symbols-outlined {
  font-variation-settings:
  'FILL' 0,
  'wght' 400,
  'GRAD' 0,
  'opsz' 48
}


.brand-section{
  padding: 100px 0;

}

.modern .brand-page-main-container:not(.brand-scroll-container){
  /* padding: 0 5vw; */
  background-color: rgb(249, 249, 249);
}

.poppy .brand-page-main-container:not(.brand-scroll-container){
  /* padding: 0 5vw; */
  background: linear-gradient(100deg,#ea426f 5%, rgba(221, 255, 119, 1)  );
}


@font-face {
  font-family: 'syncopate-regular';
  src: url('./assets/Syncopate-Regular.ttf')format('truetype');
}

@font-face {
  font-family: 'syncopate-bold';
  src: url('./assets/Syncopate-Bold.ttf')format('truetype');
}

@font-face {
  font-family: 'poppins';
  src: url('./assets/Poppins-Regular.ttf')format('truetype');
}


@font-face {
  font-family: 'Bai Jamjuree';
  src: url('./assets/BaiJamjuree-Regular.ttf')format('truetype');
}


/* react-app/src/assets/DrukCond-Super-Cy-Gr-Web.ttf */


@font-face {
  font-family: 'Druckond';
  src: url('./assets/DrukCond-Super-Cy-Gr-Web.ttf')format('truetype');
}
