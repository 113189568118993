.whole-page-cart {
  width: 100vh;
  display: flex;
  /* justify-content: space-between; */
}


.page-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 20;
  transition: left 2s ease;
  /* display: none; */
}


