.login-full-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  /* width: 300px; */
  /* border: 1px black solid; */
  margin: 0 auto;
  background: linear-gradient(90deg, #9ef9dc, #97dbf2);
  /* background: #dedede; */

}

.login-container {
  height: 550px;
  width: 400px;
  /* border: 1px solid black; */
  border-radius: 12px;
  box-shadow: 15px 15px 20px rgba(0, 0, 0, 0.3),
    -15px -15px 50px rgba(84, 84, 84, 0.245);

  /* padding:80px 50px 50px; */
  background-color: rgba(236, 236, 236, 1);
}

.form-container {
  display: flex;
  /* padding: 50px 25px; */
  flex-direction: column;
  justify-content: center;
  margin: 0px 0px;
  /* height: 90%; */
}

.login-input {
  display: flex;
  flex-direction: column;
  border: none;
  outline: none;
  background: transparent;
  border-radius: 10px;
  width: 70%;
  height: 30px;
  padding: 8px 20px;

  /* border-radius: 5px; */
  margin: 10px 20px;
  box-shadow: inset 5px 5px 10px rgba(0, 0, 0, 0.05), inset -5px -5px 10px rgba(255, 255, 255, 1);
}

.login-errors {
  color: red;
}


.login-title {
  padding: 0px;
  font-size: 40px;
  padding-top: 35px;
  margin-bottom: 0px;
  margin-left: 20px;
}

.login-continue {
  margin-top: 0px;
  margin-left: 20px;
  font-weight: 100;
}


.login-form-button-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 20px 30px;
}

.login-form-button {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.1),
    -5px -5px 10px #fff;
  width: 80%;
  padding: 15px 20px;
  cursor: pointer;
  border: none;
  outline: none;
  background: transparent;
  border-radius: 10px;
  font-weight: 600;
  transition: box-shadow ease-in 3s;
  border: 3px solid transparent; /* Add a transparent border */
}

.login-form-button:hover{
  box-shadow: inset 5px 5px 10px rgba(0, 0, 0, 0.1),
  inset -5px -5px 10px #fff;
  border: 3px solid rgba(0, 106, 255, 0.4);
}

.login-input:focus {

  border-color: rgba(0, 106, 255, 0.4);
  box-shadow: inset 5px 5px 10px rgba(0, 0, 0, 0.1),
    inset -5px -5px 10px #ffffff;
}
