.landing-page-scroll-container {
  box-sizing: border-box;
  /* background-color: rgb(197, 197, 197); */
  display: flex;
  flex-direction: row;
  /* width: 40vw; */
  margin: 0 0;
  padding: 0 0;
  /* border-top: 5px solid black; */
  /* border-bottom: 5px solid black; */
  height: 80vh;
}



.landing-page-logos {
  overflow: hidden;
  padding-top: 10px;
  padding-bottom: 10px;
  white-space: nowrap;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}



.landing-page-logos-slide div {
  font-size: 45px;
  margin: 50px 0px;

  /* border: 1px solid rgb(255, 255, 255); */
  border-top: 4px solid white;
  border-bottom: 4px solid white;

  padding: 10px 10px;
  background-color: color;
  width: 100%;
  text-align: center;
  color: black;
  font-family: 'Courier New', Courier, monospace;
  font-family: 'Bai Jamjuree';
  font-weight: bold;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  height: 100%;

}

.landing-page-logos-slide {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  /* flex-direction: row;
  align-items: center; */

  /* height: 50vh; */


  animation: 20s landingslide linear infinite;
}


@keyframes landingslide {
  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(-100%);
  }
}



/* Mobile styles for landing page */
@media (max-width: 767px) {
  .landing-page-scroll-container {
    height: 50vh;
  }

  .landing-page-right-side {
    padding-top: 12vh;
  }

  .landing-page-logos-slide div {
    font-size: 34px;
    margin: 20px 0px;
    padding: 5px 5px;
  }
}
