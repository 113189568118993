.main-container {
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  width: 100%;
  align-items: center;
  justify-content: center;
  /* margin-right: 250px; */
}


.main-dashboard-brand-name{
  font-size: 40px;
  font-weight: bold;
  font-family: 'syncopate-bold';
  text-transform: uppercase;
  padding: 20px 0px;
  letter-spacing: 0.1px;
}

.grid-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;


}

.grid-row {

  display: flex;
  flex-direction: row;
  margin: 50px 50px;
  /* width: 70%; */

}

.grid-cell {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 50px 50px;
  /* background-color: rgb(166, 166, 166); */
  margin: 0px 10px;
  width: 10vw;
  height: 10vw;
  border-radius: 20px;
  cursor: pointer;
  border-radius: 12px;
  border: 2px solid black;
  /* box-shadow: 15px 15px 20px rgba(0, 0, 0, 0.3),
    -15px -15px 50px rgba(253, 253, 253, 0.7); */
  box-shadow: 0px 8px 6px 0px black;
  transition: transform 0.1s ease-in-out, box-shadow 0.1s ease-in-out, border 0.1s ease-in-out;

  /* padding:80px 50px 50px; */
  background-color: rgba(236, 236, 236, 1);
  backdrop-filter: blur(15px);

  /* box-shadow: 2px 2px 0px 2px green; */

}

.grid-cell:hover {
  transform: translateY(10px);
  box-shadow: none;
  border: 2px solid black;

}

.inside-text {
  font-size: 30px;
  padding: 0px 0px;

  font-weight: bold;
  text-align: center;
  /* font-family: 'syncopate-bold'; */
  letter-spacing: 1px;
  /* text-transform: uppercase; */
}
