.landing-page-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: linear-gradient(60deg, #ffffff 25%, rgb(119, 255, 212, 0.6) 75%, rgba(119, 207, 255, 0.6));
}

.landing-page-header {
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", Segoe UI Symbol, "Noto Color Emoji";
  font-weight: 700;
  transition-delay: .5s;
  letter-spacing: -.025em;
  font-size: 5.75rem;
  line-height: 1.1;
}

.landing-page-top-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-top: 70px;
  width: 90%;
  height: 80vh;
}

.landing-page-right-side {
  /* width:70% */
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.landing-page-left-side {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.landing-page-middle-container-text {
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", Segoe UI Symbol, "Noto Color Emoji";
  font-weight: 700;
  transition-delay: .5s;
  letter-spacing: -.025em;
  font-size: 3.75rem;
  line-height: 1;
  padding-bottom: 6vh;

}

.landing-page-middle-container {
  height: 100vh;
  padding-top: 25vh;
  width: 80%;
  padding-bottom: 30vh;
}


.landing-page-signup {
  /* margin-left: 30px; */
  cursor: pointer;
  color: white;
  border: none;
  font-style: bold;
  font-weight: 600;
  font-size: 20px;
  /* border: 1px solid black; */
  padding: 15px 35px;
  border-radius: 25px;
  background-color: black;
  margin-right: 10vw;
  margin-top: 2vh;
}

.landing-page-signup:hover {
  background-color: rgb(69, 69, 69);
}


.right-side-image {
  width: 200px;

}





.landing-page-your-store-text {

  font-size: 5vw;
  padding-bottom: 7vh;
  font-weight: bold;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", Segoe UI Symbol, "Noto Color Emoji";
}


.landing-page-your-store-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  /* height: 100vh; */
}


.landing-page-example{
  width: 80%;
  box-shadow: 4px 4px 20px 10px rgb(209, 220, 220);
  /* height: 70%; */
  margin-bottom: 10vh;
}


.landing-page-two-section{
display: flex;
flex-direction: row;
padding-top: 0;
padding-bottom: 20vh;
/* justify-content: space-between; */
}


.landing-page-individual-sections{
  width: 30vw;
  padding: 2vw 8vw;
}


.landing-page-indivdual-section-top-border{
  border-top: 4px solid rgb(109, 222, 253);
  padding-bottom: 5vh;
}

.landing-page-indivdual-section-top-title{
  font-weight: bold;
  font-size: 2vw;
  margin-bottom: 2.5vh;
}

.landing-page-indivdual-section-paragraph{
  font-size: 25px;
}



.landing-page-description-cards{
  display: flex;
  justify-content: center;
}



/* Mobile styles for landing page */
@media (max-width: 767px) {
  .landing-page-container {
    padding: 20px;
  }

  .landing-page-header {
    font-size: 2.5rem;
    text-align: center;
  }

  .landing-page-top-container {
    flex-direction: column;
    height: auto;
    padding-top: 40px;
  }

  .landing-page-right-side,
  .landing-page-left-side {
    width: 100%;
  }

  .landing-page-middle-container-text {
    font-size: 2rem;
    text-align: center;
    padding-bottom: 3vh;
  }

  .landing-page-middle-container {
    height: auto;
    padding-top: 10vh;
    padding-bottom: 10vh;
  }

  .landing-page-signup {
    width: 100%;
    max-width: 300px;
    margin-right: 0;
  }

  .landing-page-your-store-text {
    font-size: 2rem;
    padding-bottom: 4vh;
  }

  .landing-page-example {
    width: 100%;
  }

  .landing-page-two-section {
    flex-direction: column;
    padding-bottom: 10vh;
  }

  .landing-page-individual-sections {
    width: 100%;
    padding: 4vw 0;
  }

  .landing-page-indivdual-section-top-title {
    font-size: 1.5rem;
  }

  .landing-page-indivdual-section-paragraph {
    font-size: 16px;
  }

  .landing-page-description-cards {
    flex-direction: column;
    align-items: center;
  }
}
