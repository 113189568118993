.brand-header-container {
  position: relative;
  padding: 0 5vw;

}

.modern .brand-header-menu {
  position: absolute;
  left: 0;
  top: 100px;
  letter-spacing: 1px;
  font-family: 'syncopate-bold', sans-serif;
  color: #626262;
  text-transform: uppercase;

  li {
    margin: 10px 0;
    list-style-type: none;
  }
}


.poppy .brand-header-menu {
  position: absolute;
  left: 0;
  top: 100px;
  letter-spacing: 1px;
  font-style: "Neue Haas Unica", sans-serif;
  color: #626262;
  text-transform: uppercase;

  li {
    margin: 10px 0;
    list-style-type: none;
  }
}


.modern #brand-header-text {
  font-size: 9.8vw;
  text-transform: uppercase;
  text-align: center;
  font-family: 'Bai Jamjuree', sans-serif;
  font-weight: 600;
  margin: 0 0;
  line-height: 1;
  word-wrap: break-word;
  padding: 0 5vw;
}



.poppy #brand-header-text {
  font-size: 9vw;
  text-transform: uppercase;
  text-align: center;
  color: white;
  font-style: "Neue Haas Unica", sans-serif;
  text-shadow: -2px -2px 0 #000,
  2px -2px 0 #000,
  -2px 2px 0 #000,
  2px 2px 0 #000;
  text-rendering: optimizeLegibility;
  /* font-weight: 600; */
  margin: 0 0;
  line-height: 1;
  word-wrap: break-word;
  padding: 0 5vw;
  -webkit-text-stroke: 2px black;
  -webkit-text-fill-color: rgb(255, 255, 84);
  /* Optional: set the text color */
  text-shadow: 5px 7px 3px #d2213f;
  letter-spacing: -3px;
}
