.all-brands-container {
  background-color: #161616;
  /* height: 100vh; */
  display: flex;
  flex-direction: column;

}


.all-brands-row-container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 40px;
  padding: 5vh 0px;
}

.all-brands-top-text-container {
  /* width: 100%; */
  padding: 10vh 3.5vw;
  padding-top: 10vh;
  padding-bottom: 2vh;

}

.all-brands-top-text {
  font-size: 5rem;
  font-family: 'Druckond';
  text-transform: uppercase;
  color: rgba(255, 247, 233, .6);

}
.brand-item {
  width: 29.33%;
  position: relative;
  cursor: pointer;
}

.all-brands-images {
  width: 100%;
  object-fit: cover;
  height: 70vh;
  border-radius: 15px;
  /* border: 2px white solid; */
  transition: background-color 0.3s ease;
}

.brand-item:hover .all-brands-images {
  background-color: transparent;
}

.brand-item:hover .brand-image-overlay {
  opacity: 0.2;
}

.brand-image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  border-radius: 15px;
  color: rgba(255, 255, 255, 0.856);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1;
  transition: opacity 0.3s ease;
}

.brand-inside-text {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.brand-name {
  font-size: 30px;
  font-family: 'syncopate-bold';
  text-transform: uppercase;
  text-align: center;
  word-wrap: break-word;
  margin-bottom: 5px;
  width: 100%;
}

.brand-slogan {
  font-weight: 100;
  font-size: 15px;
}
