.add-product-left-right-container {

  width: 70vw;
  justify-content: center;
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  overflow: hidden;
  overflow-y: scroll;

}

.add-product-left-container-main {

  /* padding:25px 10px; */
  /* margin-bottom:; */
  margin-left: 20px;
  width: auto;
}

.add-product-label {
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 90%;
}


.title-description {
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
  /* height: 250px; */

}

.add-product-title-container{
  display: flex;
  justify-content: center;

}
.add-product-title {
  /* text-align: left; */
  width: 93%;

}

.add-product-input {
  width: 90%;
}

.add-product-input-container{
  display: flex;
  justify-content: center;
  width: 100%;
}

.add-product-input{
  width: 90%;
}



.add-product-left-container-individual {
  background-color: white;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  margin-right: 10px;
  margin-bottom: 20px;
  border-radius: 7px;
  box-shadow: 0px 2px 5px 0px rgb(169, 169, 169);
}

.add-product-right-container-individual {
  background-color: white;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  margin-right: 10px;
  margin-bottom: 20px;
  border-radius: 7px;
  box-shadow: 0px 2px 5px 0px rgb(169, 169, 169);
}



.status {
  height: 100px;
}



.add-product-right-container {
  margin-left: 15px;
  width: auto;
}


.add-product-left-right-container::-webkit-scrollbar {
  width: 6px;
  background-color: transparent;
}

.add-product-left-right-container::-webkit-scrollbar-thumb {
  background-color: rgba(158, 158, 158, 0.2);
  border-radius: 3px;
}

.add-product-left-right-container::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.3);
}

.add-product-left-right-container::-webkit-scrollbar-track {
  background-color: transparent;
}

.container {
  display: flex;
  flex-wrap: wrap;
}

.column {
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: white;
}

.row {
  flex: 1;
  display: flex;
  flex-direction: row;
}

.box {
  flex: 1;
  box-shadow: 0px 2px 2px 0px rgb(169, 169, 169);
  height: 200px;
}

.feed-test-container {
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
  width: 100vw;
  box-sizing: border-box;
}


.feed-test-photo {
  flex: 0 0 .33%;
  max-width: 90.33%;
  /* box-sizing: border-box; */
  padding: 10px;
  position: relative;
}

.feed-test-photo img {
  width: 100%;
  height: auto;
  border-radius: 15px;
  filter: brightness(100%);

}


.error {
  color: red;
}







.add-product-image-preview-container {
  position: relative;
  display: flex;
  align-items: center;
}

.add-product-image-preview-left{
  width: 60%;
}

.add-product-image-preview-right{
/* width: 50%; */
}



.add-product-image-preview {
  /* border: 2px dashed #ccc; */
  display: flex;
  align-items: center;
  justify-content: center;
  width: 200px;
  height: 200px;
  border-radius: 5px;
  cursor: pointer;

  overflow: hidden;
}


.add-product-image-preview-right {
  /* border: 2px dashed #ccc; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 100px;
  border-radius: 5px;
  cursor: pointer;

  /* overflow: hidden; */
}


.add-product-image-preview-right-top{
  display: flex;
}




.add-product-image-preview.placeholder {
  background-color: #f9f9f9;
  border-radius: 5px;
  border: 2px dashed #ccc;
}

.add-product-image-preview-right.placeholder {
  background-color: #f9f9f9;
  border: 2px dashed #ccc;
  border-radius: 5px;
}

.add-product-image-preview-right.placeholder .placeholder-text {
  color: #999;
  font-weight: bold;
}

.add-product-image-preview.placeholder .placeholder-text {
  color: #999;
  font-weight: bold;
}



.add-product-image-preview img {
  width: 200px;
  height: 200px;
  object-fit: cover;
}

.add-product-image-preview-right img {
  width: 90px;
  height: 90px;
  object-fit: cover;
  border-radius: 5px;
}


.placeholder-text {
  font-size: 12px;
}




.add-product-image-media-text{
  margin-bottom: 20px;
}


.status{
  padding-top: 20px;
  padding-left: 10px;
}


.title-description{
  padding-top: 20px;
  padding-left: 10px;
}

.publishing{
  height: 250px;
}

.pricing label{
  display: flex;
  /* flex-direction: column; */
  /* padding-right: 20px; */
  width: 30%;
}

.pricing input{
 /* margin-top: 10px; */
}


.pricing{

}

.add-product-page-price{
  margin-left: 20px;
}
